import { TableHeadFilterOption } from "@sellernote/_shared-for-admin/src/hooks/common/useTableHeadFilter";

import {
  Currency,
  FreightType,
  ItemUnitMeasurement,
  Liner,
  ShipmentType,
} from "../../types/common/common";
import {
  AdminBidContainerInfo,
  AdminBidDetail,
  ApplyBidFeeData,
  BidCopyTarget,
  ShipmentItem,
} from "../../types/forwarding/adminBid";
import { ForwardingAdminUserAuthority } from "../../types/forwarding/adminUser";
import {
  BidProductInfo,
  BidProjectStatus,
  BidServiceType,
  ContainerType,
} from "../../types/forwarding/bid";
import { PartnerBusinessArea } from "../../types/forwarding/partner";
import { AccountPayable, ExchangeRate } from "../../types/forwarding/trello";

import {
  ADMIN_BID_QUOTATION_CONSOLIDATION_COMMENT,
  ADMIN_BID_QUOTATION_FCL_COMMENT,
  ADMIN_BID_QUOTATION_LCL_AND_AIR_COMMENT,
  ADMIN_BID_QUOTATION_OCEANTICKET_COMMENT,
  ADMIN_BID_QUOTATION_SG_COMMENT,
  ADMIN_BID_SCHEDULE_CIF_CFR_COMMENT,
} from "../../constants/forwarding/adminBid";
import { checkEqualObject } from "../common/etc";
import { omitWithEllipsis } from "../common/string";
import {
  extractCbmNumberFromCbmString,
  findExchangeRate,
} from "./tradingStatement";

/**
 * 어카운트 페이 어블 목록에서 통관 파트너의 이름을 반환하는 함수
 *
 * @param accountPayables - 어카운트 페이 어블 목록
 * @returns 통관 파트너의 이름을 반환, 통관 파트너가 없으면 "화주발송"을 반환
 */
const getCustomsPartnerName = (accountPayables: AccountPayable[]) => {
  const customsAccountPayable = accountPayables.find((v) => {
    return v.domain === "customs";
  });

  if (customsAccountPayable?.partner) {
    return customsAccountPayable.partner.name;
  }
  return "화주발송";
};

/**
 * 운송 모드를 반환하는 함수
 *
 * @param freightType - 화물 유형
 * @param serviceType - 서비스 유형
 * @returns 운송 모드
 */
const getTransportMode = (
  freightType: FreightType,
  serviceType: BidServiceType
) => {
  if (freightType === "FCL") {
    return "FCL";
  }
  if (freightType === "LCL" && serviceType === "consolidation") {
    return "EXPRESS";
  }

  if (freightType === "AIR") {
    return "AIR";
  }
  return "LCL";
};

/**
 * 사용자가 마스터 권한을 가지고 있는지 확인하는 함수
 *
 * @param authority - 사용자의 권한
 * @returns 사용자가 마스터 권한을 가지고 있는지 여부
 */
const checkIsMasterAuthority = (
  authority: ForwardingAdminUserAuthority | undefined
) => {
  if (authority === "master") {
    return true;
  }
  return false;
};

/**
 * 견적 항목을 수정할 수 있는지 확인하는 함수
 *
 * @param projectStatus - 프로젝트 상태
 * @param authority - 사용자의 권한
 * @returns 견적 항목을 수정할 수 있는지 여부
 */
const checkCanEditBidItem = (
  projectStatus: BidProjectStatus,
  authority: ForwardingAdminUserAuthority | undefined
) => {
  if (projectStatus === "settlementComplete") {
    if (checkIsMasterAuthority(authority)) {
      return true;
    }
    return false;
  }
  return false;
};

/**
 * 견적 코멘트를 반환하는 함수
 *
 * @param bidDetail - 견적 상세 데이터
 * @returns 견적 코멘트
 */
const getQuotationComment = (bidDetail: AdminBidDetail | undefined) => {
  if (!bidDetail?.isImport) {
    return "";
  }
  if (bidDetail?.locale !== "KR") {
    return ADMIN_BID_QUOTATION_SG_COMMENT;
  }

  if (bidDetail.freightType === "FCL") {
    if (bidDetail.incoterms === "CIF" || bidDetail.incoterms === "CFR") {
      return ADMIN_BID_SCHEDULE_CIF_CFR_COMMENT;
    }
    return ADMIN_BID_QUOTATION_FCL_COMMENT;
  }

  if (bidDetail.serviceType === "oceanTicket") {
    return ADMIN_BID_QUOTATION_OCEANTICKET_COMMENT;
  }

  if (bidDetail.serviceType === "consolidation") {
    return ADMIN_BID_QUOTATION_CONSOLIDATION_COMMENT;
  }

  return ADMIN_BID_QUOTATION_LCL_AND_AIR_COMMENT;
};

/**
 * 선사 목록에서 선사 이름에 해당하는 선사 ID를 반환하는 함수
 *
 * @param linerList - 선사 목록
 * @param linerName - 선사 이름
 * @returns 선사 ID, 선사 목록이 없거나 이름에 해당하는 선사가 없으면 0을 반환
 */
const getLinerId = (linerList: Liner[] | undefined, linerName: string) => {
  if (!linerList) {
    return 0;
  }

  return (
    linerList.find((liner) => {
      return liner.name === linerName;
    })?.id || 0
  );
};

/**
 * 내륙 운임 목록에서 화물 유형에 따른 내륙 운송 유형을 반환하는 함수
 *
 * @param inlandFares - 내륙 운임 목록
 * @param freightType - 화물 유형
 * @returns 내륙 운송 유형, 내륙 운임 목록에 해당 유형이 없으면 null을 반환
 */
const getInlandType = (
  inlandFares: ApplyBidFeeData[],
  freightType: FreightType
) => {
  const findTruckingChargeType = inlandFares.find((v) => {
    return v.itemUnitMeasurement;
  });

  if (findTruckingChargeType) {
    if (findTruckingChargeType.note.includes("독차")) {
      return "sole";
    }

    if (findTruckingChargeType.note.includes("합차")) {
      return "consol";
    }

    if (findTruckingChargeType.note.includes("화물 택배")) {
      return "parcel";
    }
  }

  return null;
};

/**
 * 기존 의뢰 물품 정보와 수정된 물품 정보를 비교 후 이름만 변경했는지 확인해주는 함수
 * @returns 물품 수정 시 이름만 변경했는지에 대한 boolean값
 */
/**
 * 주어진 두 배열의 객체에서 이름(name) 속성을 제거한 후, 두 배열이 동일한지 확인합니다.
 *
 * @param originItemData - 원본 아이템 데이터 배열 (AdminBidContainerInfo[] 또는 BidProductInfo[])
 * @param newItemData - 새로운 아이템 데이터 배열 (AdminBidContainerInfo[] 또는 BidProductInfo[])
 * @return 두 배열이 이름 속성을 제외하고 동일한지 여부를 나타내는 boolean 값
 *
 * @remarks
 * 이 함수는 객체의 이름 속성을 제거한 후 비교를 수행하므로, 이름 속성 외의 다른 속성들이 동일한지 확인할 때 사용해야 합니다.
 */
const checkAdminBidItemOnlyRenamed = ({
  originItemData,
  newItemData,
}: {
  originItemData: AdminBidContainerInfo[] | BidProductInfo[];
  newItemData: AdminBidContainerInfo[] | BidProductInfo[];
}) => {
  const originItemDataWithNameRemoved = originItemData.map((item) => {
    return {
      ...item,
      name: "",
    };
  });

  const newItemDataWithNameRemoved = newItemData.map((item) => {
    return {
      ...item,
      name: "",
    };
  });

  return checkEqualObject(
    originItemDataWithNameRemoved,
    newItemDataWithNameRemoved
  );
};

/**
 * 의뢰 상세에 해당 도메인 컨택 파트너가 있는지 확인해준다.
 *
 * @param bidDetail - 의뢰 상세 데이터
 * @param domain - 파트너의 도메인
 * @returns 도메인 파트너가 의뢰 존재 여부값
 */
const checkIfBidHaveDomainPartner = (
  bidDetail: AdminBidDetail,
  domain: PartnerBusinessArea
) => {
  const domainPartner = bidDetail.accountPayables.find((accountPayable) => {
    return (
      // 어카운트만 추가하고 파트너를 등록하지 않는 경우가 있음
      accountPayable.partnerCompanyId && accountPayable.domain === domain
    );
  });

  if (domainPartner) {
    return true;
  }
  return false;
};

/**
 * 의뢰 파트너가 가지고 있는 language가 en인지 확인
 *
 * @param bidDetail - 의뢰 상세 데이터
 * @param domain - 파트너의 도메인
 * @returns boolean
 */
const checkBidPartnerLanguageIsEn = (
  bidDetail: AdminBidDetail,
  domain: PartnerBusinessArea
) => {
  const domainPartner = bidDetail.accountPayables.find((accountPayable) => {
    return (
      // 어카운트만 추가하고 파트너를 등록하지 않는 경우가 있음
      accountPayable.partnerCompanyId && accountPayable.domain === domain
    );
  });

  if (domainPartner) {
    return domainPartner.partner.language === "en";
  }
  return false;
};

/**
 * shipmentItemList에서 중복을 제거한 containersType들이 있는 배열을 리턴해준다.
 *
 * @param shipmentItemList - 배송 항목 목록
 * @returns 중복이 제거된 컨테이너 타입 배열
 */
const getUniqueContainerTypeArr = (shipmentItemList: ShipmentItem[]) => {
  const containerTypeArray = shipmentItemList?.map((v) => {
    return v.containerType;
  });
  // 컨테이너 타입의 중복을 제거
  const uniqueContainerTypeArr = [...new Set(containerTypeArray)];

  return uniqueContainerTypeArr;
};

/**
 * 특정 컨테이너 타입에 해당하는 수량을 계산하는 함수
 *
 * @param shipmentItemList - 배송 항목 목록
 * @param containerType - 컨테이너 타입
 * @returns 해당 컨테이너 타입의 총 수량
 */
const getAmountByContainerType = (
  shipmentItemList: ShipmentItem[],
  containerType: ContainerType | null
) => {
  return shipmentItemList.reduce((acc, cur) => {
    if (cur.containerType === containerType) {
      acc = acc + cur.quantity;
    }

    return acc;
  }, 0);
};

/**
 * 배송 항목 목록에서 컨테이너 타입과 해당 수량을 배열로 반환하는 함수
 *
 * @param shipmentItemList - 배송 항목 목록
 * @returns 컨테이너 타입과 해당 수량을 포함한 객체 배열
 */
const getContainerTypeAndAmountArr = (shipmentItemList: ShipmentItem[]) => {
  const uniqueContainerTypeArr = getUniqueContainerTypeArr(shipmentItemList);

  return uniqueContainerTypeArr.map((v) => {
    return {
      containersType: v,
      amount: getAmountByContainerType(shipmentItemList, v),
    };
  });
};

/**
 * 단위가 RTON인 콘솔의뢰일 때 수량을 준다.
 *
 * @param unipassTotalCBM - 유니패스 총 CBM
 * @param wareHouseCbm - 창고 CBM
 * @param totalWeight - 총 무게
 * @param totalCBM - 총 CBM
 * @param shipmentSupply - 배송 공급량
 * @returns 콘솔의뢰일 때의 수량
 *
 * @remarks
 * 유니패스 연동 시엔 의뢰 데이터가 아닌 유니패스 CBM 데이터를 보여준다.
 */
const getConsolidationRtonAmount = ({
  shipmentSupply,
  unipassTotalCBM,
  wareHouseCbm,
  totalWeight,
  totalCBM,
}: {
  unipassTotalCBM: string | null;
  wareHouseCbm: number | undefined;
  totalWeight: number | undefined;
  totalCBM: number | undefined;
  shipmentSupply: number;
}) => {
  // 유니패스 연동 시엔 의뢰 데이터가 아닌 유니패스 CBM 데이터를 보여준다.
  if (unipassTotalCBM) {
    return extractCbmNumberFromCbmString(unipassTotalCBM);
  }

  // 창고 계산기준에 따라서 1CBM/250KG인 경우 총 무게에 * 4를 한 뒤 총 CBM과 비교한다.
  if (
    wareHouseCbm === 250 &&
    totalCBM &&
    totalWeight &&
    totalWeight * 4 > totalCBM &&
    totalWeight * 4 > 1
  ) {
    return totalWeight * 4;
  }

  return shipmentSupply < 1 ? 1 : shipmentSupply;
};

/**
 * 3R.TON 단위의 수량을 계산하는 함수
 *
 * @param shipmentSupply - 배송 공급량
 * @returns 3R.TON 단위의 수량
 */
const get3RtonAmount = (shipmentSupply: number) => shipmentSupply / 3;

/**
 * 0.1 R.TON 단위의 수량을 계산하는 함수
 *
 * @param shipmentSupply - 배송 공급량
 * @returns 0.1 R.TON 단위의 수량
 */
const get0_1RtonAmount = (shipmentSupply: number) =>
  Math.ceil(shipmentSupply * 10);

/**
 * 구분된 20DRY, 40DRY, 40HQ, 20RF, 40RF 컨테이너 견적단위일 때 수량을 준다.
 *
 * @param shipmentItemList - 배송 항목 목록
 * @param itemUnitMeasurement - 항목 단위 측정
 * @returns 구분된 컨테이너 단위의 수량
 */
const getCategorizedContainerAmount = ({
  shipmentItemList,
  itemUnitMeasurement,
}: {
  shipmentItemList: ShipmentItem[];
  itemUnitMeasurement: ItemUnitMeasurement;
}) => {
  const containerTypeAndAmountArr =
    getContainerTypeAndAmountArr(shipmentItemList);

  const containerAmount = containerTypeAndAmountArr.find(
    (v) => v.containersType && itemUnitMeasurement.includes(v.containersType)
  );
  return containerAmount?.amount || 0;
};

/**
 * 견적단위가 구분되지 않은 일반 CNTR인 경우에 수량을 준다.
 *
 * @param shipmentItemList - 배송 항목 목록
 * @returns 일반 CNTR 단위의 수량
 */
const getContainerAmount = (shipmentItemList: ShipmentItem[]) => {
  return shipmentItemList.reduce((acc, { quantity }) => acc + quantity, 0);
};

/**
 * 거래명세서에서 AIR일 때 직접 입력한 값을 수량으로 준다.
 *
 * @param directAirRton - 직접 입력한 R.TON 값
 * @returns 직접 입력한 R.TON 값
 */
const getDirectAirRtonAmount = (directAirRton: number) => {
  return directAirRton < 1 ? 1 : directAirRton;
};

/**
 * 출금액에서 LCL이고 RTON인 경우에 수량을 준다.
 *
 * @param unipassTotalCBM - 유니패스 총 CBM
 * @param shipmentSupply - 배송 공급량
 * @returns LCL이고 RTON인 경우의 수량
 */
const getLCLRtonAmount = ({
  unipassTotalCBM,
  shipmentSupply,
}: {
  unipassTotalCBM: string | null;
  shipmentSupply: number;
}) => {
  if (unipassTotalCBM) {
    const unipassAmount = extractCbmNumberFromCbmString(unipassTotalCBM);

    return unipassAmount < 1 ? 1 : unipassAmount;
  }

  return shipmentSupply < 1 ? 1 : shipmentSupply;
};

/**
 * 견적단위에 따라 수량을 구하는 함수
 *
 * @param shipmentSupply - 배송 공급량
 * @param itemUnitMeasurement - 항목 단위 측정
 * @param shipmentItemList - 배송 항목 목록
 * @param serviceType - 서비스 유형
 * @param wareHouseCbm - 창고 CBM
 * @param totalWeight - 총 무게
 * @param totalCBM - 총 CBM
 * @param unipassTotalCBM - 유니패스 총 CBM
 * @param freightType - 화물 유형
 * @param directAirRton - 거래명세서에서 AIR 의뢰일 때 직접 입력하는 Rton
 * @returns 견적단위에 따른 수량
 *
 * @remarks
 * 특정 조건에 따라 수량을 계산하며, 조건에 맞지 않는 경우 기본적으로 배송 공급량을 반환합니다.
 */
const getAmountByItemUnitMeasurement = ({
  shipmentSupply,
  itemUnitMeasurement,
  shipmentItemList,
  serviceType,
  wareHouseCbm,
  totalWeight,
  totalCBM,
  unipassTotalCBM,
  freightType,
  directAirRton,
}: {
  shipmentSupply: number;
  itemUnitMeasurement: ItemUnitMeasurement;
  shipmentItemList: ShipmentItem[];
  serviceType: BidServiceType | undefined;
  wareHouseCbm: number | undefined;
  totalWeight: number | undefined;
  totalCBM: number | undefined;
  unipassTotalCBM: string | null;
  freightType: FreightType;
  directAirRton?: number;
}) => {
  if (serviceType === "consolidation" && itemUnitMeasurement === "R.TON") {
    return getConsolidationRtonAmount({
      unipassTotalCBM,
      wareHouseCbm,
      totalWeight,
      totalCBM,
      shipmentSupply,
    });
  }

  if (["B/L", "TRUCK", "ENTRY"].includes(itemUnitMeasurement)) {
    return 1;
  }

  if (itemUnitMeasurement === "3R.TON") {
    return get3RtonAmount(shipmentSupply);
  }

  if (itemUnitMeasurement === "0.1 R.TON") {
    return get0_1RtonAmount(shipmentSupply);
  }

  if (
    ["CNTR(20DRY)", "CNTR(40DRY)", "CNTR(40HQ)", "20RF", "40RF"].includes(
      itemUnitMeasurement
    )
  ) {
    return getCategorizedContainerAmount({
      shipmentItemList,
      itemUnitMeasurement,
    });
  }

  if (itemUnitMeasurement === "CNTR" && freightType === "FCL") {
    return getContainerAmount(shipmentItemList);
  }

  if (directAirRton) {
    return getDirectAirRtonAmount(directAirRton);
  }

  // 조건에 걸리지 않는 경우 의뢰의 supply를 그대로 리턴
  return shipmentSupply < 1 ? 1 : shipmentSupply;
};

/**
 * 화물 유형에 따라 운송사를 반환하는 함수
 *
 * @param freightType - 화물 유형
 * @returns 운송사명
 */
const getLabelForCarrier = (freightType: FreightType) => {
  if (freightType === "FCL") {
    return "선사명";
  }

  return "콘솔사명";
};

/**
 * 견적 항목 데이터에 존재하는 currency값만 추출하는 함수
 *
 * @param feeData - 견적 항목 데이터
 * @returns 추출된 currency 배열
 */
const extractCurrencyInFeeData = (feeData: ApplyBidFeeData[]) => {
  if (!feeData) {
    return [];
  }
  const currencySet = feeData?.reduce((acc, cur) => {
    // KRW은 필요없음
    if (cur.currency !== "KRW") {
      acc.add(cur.currency);
    }

    return acc;
  }, new Set());

  return Array.from(currencySet);
};

/**
 * 견적 요청을 할 때 필요한 currency payload 값을 만들어주는 함수
 *
 * @param localFee - 로컬 비용 데이터
 * @param freightFee - 화물 비용 데이터
 * @param domesticFee - 국내 비용 데이터
 * @param inlandFee - 내륙 비용 데이터
 * @param otherFee - 기타 비용 데이터
 * @param taxFee - 세금 비용 데이터
 * @param exchangeRateList - 환율 목록
 * @returns 견적 요청에 필요한 currency payload 값
 */
const getQuotationDataCurrency = ({
  localFee,
  freightFee,
  domesticFee,
  inlandFee,
  otherFee,
  taxFee,
  exchangeRateList,
}: {
  localFee: ApplyBidFeeData[];
  freightFee: ApplyBidFeeData[];
  domesticFee: ApplyBidFeeData[];
  inlandFee: ApplyBidFeeData[];
  otherFee: ApplyBidFeeData[];
  taxFee: ApplyBidFeeData[];
  exchangeRateList?: ExchangeRate[];
}) => {
  const domesticFeeCurrency = extractCurrencyInFeeData(domesticFee);
  const localFeeCurrency = extractCurrencyInFeeData(localFee);
  const freightFeeCurrency = extractCurrencyInFeeData(freightFee);
  const inlandFeeCurrency = extractCurrencyInFeeData(inlandFee);
  const otherFeeCurrency = extractCurrencyInFeeData(otherFee);
  const taxFeeCurrency = extractCurrencyInFeeData(taxFee);

  // 각 항목별 중복으로 존재하는 통화를 제거한다.
  const filteredDataByCurrencyDuplicate = [
    ...new Set([
      ...domesticFeeCurrency,
      ...localFeeCurrency,
      ...freightFeeCurrency,
      ...inlandFeeCurrency,
      ...otherFeeCurrency,
      ...taxFeeCurrency,
    ]),
  ];

  const bidQuotationCurrency = exchangeRateList?.reduce(
    (acc, cur) => {
      if (filteredDataByCurrencyDuplicate.includes(cur.currency)) {
        // api로 받는 exchangeRate의 rate값은 string인데 요청 값은 number로 보내야함
        acc = { ...acc, [cur.currency]: Number(cur.rate) };
      }
      return acc;
    },
    // 만약 견적에서 외화가 없는 경우 기본값으로 USD를 담아서 요청
    { USD: findExchangeRate(exchangeRateList, "USD") }
  );

  return bidQuotationCurrency as Record<Currency, number>;
};

/**
 * 정규식으로 <br> 태그를 \n으로 대체하는 함수
 *
 * @param value - 변환할 문자열
 * @returns 변환된 문자열
 */
const replaceBrTagsWithNewline = (value: string | undefined) => {
  if (!value) return "";

  return value.replace(/<br\s*\/?>/gi, "\n");
};

/**
 * TODO: getTitleAmongItemListName에도 건이 붙는다면 삭제
 *
 * 견적 항목 목록에서 항목 이름을 반환하는 함수
 *
 * @param items - 항목 목록
 * @param maxLength - 최대 길이
 * @returns 항목 이름 문자열
 *
 * @remarks
 * 항목 이름이 최대 길이를 초과할 경우 생략 부호(...)를 추가합니다.
 */
const getBidTableItemName = ({
  items,
  maxLength,
}: {
  items?: { name: string }[];
  maxLength?: number;
}) => {
  let result = "";

  if (items && items.length > 0) {
    result += items[0].name;

    if (maxLength) {
      result = omitWithEllipsis({
        src: result,
        maxLength,
        ellipsis: "...",
      });
    }

    if (items.length > 1) {
      result += ` 외 ${items.length - 1}건`;
    }
  }

  return result;
};

/**
 * 배송 서비스 유형을 한글로 반환하는 함수
 *
 * @param serviceType - 서비스 유형
 * @param isExpress - 특송 여부
 * @param isImport - 수입 여부
 * @returns 배송 서비스 유형을 한글로 반환
 *
 * @remarks
 * 수입 여부에 따라 수출 또는 수입 관련 서비스를 반환합니다.
 */
const getShipmentServiceTypeKr = ({
  serviceType,
  isExpress,
  isImport,
}: {
  serviceType: string;
  isExpress: boolean;
  isImport: boolean;
}) => {
  if (!isImport) return "수출";

  if (isExpress) return "특송";

  if (serviceType === "oceanTicket") return "오션티켓";

  if (serviceType === "general") return "일반의뢰";

  if (serviceType === "consolidation") return "묶음 배송";

  return "-";
};

/**
 * 의뢰복사 대상 옵션 리스트를 반환한다.
 */
const getBidCopyTargetOptionList = (
  shipmentType: ShipmentType
): TableHeadFilterOption<keyof BidCopyTarget>[] => {
  return [
    {
      value: "defaultInfo",
      label: "기본 정보, 운송 정보(운영/체크포인트), 물품 상세",
    },
    {
      value: "copyEstimatePartner",
      label: "견적파트너",
    },
    {
      value: "contactEstimatePartner",
      label: "견적파트너 컨택",
    },
    {
      value: "copyEstimate",
      label: "견적서",
    },
    {
      value: "submitEstimate",
      label: "견적서 제출",
    },
    {
      value: "acceptEstimate",
      label: "견적서 수락",
    },
    {
      value: "copyExporterInfo",
      label: "수출자 정보",
    },
    {
      value: "copyBookingPartner",
      label:
        shipmentType === "importation"
          ? "지급유형, 부킹파트너"
          : "지급유형, 현지파트너",
    },
    {
      value: "contactBookingPartner",
      label:
        shipmentType === "importation" ? "부킹파트너 컨택" : "현지파트너 컨택",
    },
  ];
};

export {
  getCustomsPartnerName,
  getTransportMode,
  checkCanEditBidItem,
  getQuotationComment,
  getLinerId,
  getInlandType,
  checkAdminBidItemOnlyRenamed,
  checkIfBidHaveDomainPartner,
  checkBidPartnerLanguageIsEn,
  getAmountByItemUnitMeasurement,
  getLabelForCarrier,
  getQuotationDataCurrency,
  replaceBrTagsWithNewline,
  getBidTableItemName,
  getShipmentServiceTypeKr,
  getContainerTypeAndAmountArr,
  getDirectAirRtonAmount,
  getLCLRtonAmount,
  getBidCopyTargetOptionList,
};
