import { InlnadTransportType } from "../../types/common/common";
import {
  ForwardingAdminFileAuthority,
  ForwardingAdminFileDomain,
} from "../../types/common/file";
import { ExportationInlandTransportType } from "../../types/forwarding/adminBidCreate";
import { ForwardingAdminUserAuthority } from "../../types/forwarding/adminUser";
import { BidProjectStatus, BidTransportType } from "../../types/forwarding/bid";
import {
  PartnerBusinessArea,
  PartnerListItem,
} from "../../types/forwarding/partner";
import {
  AccountPayable,
  ExportTrelloList,
  HouseDeliveryOrderDocumentFormType,
  InvoiceType,
  ParcelRequestDocumentFormType,
  PartnerTrelloList,
  TrelloBidDetail,
  TrelloBidList,
  TrelloBidManagement,
  WarehouseDocumentFormType,
  WithdrawalFormDetail,
} from "../../types/forwarding/trello";

import { APP_NAME } from "../../constants";
import { getCountryCodeKR } from "../common/country";
import {
  getDifferentDate,
  getRemainedDays,
  toFormattedDate,
} from "../common/date";

/**
 * TODO: 관세사 페이지 추가 시 authority customs 추가
 *
 * Trello 상세 정보를 변경할 권한이 있는지 확인합니다.
 *
 * @param authority - 사용자의 권한 (manager 또는 finance)
 * @param projectStatus - 프로젝트의 현재 상태
 * @returns 사용자가 Trello 상세 정보를 변경할 권한이 있는지 여부
 *
 * @remarks
 * manager 권한의 사용자는 정산 상태일 때만 변경 권한이 있습니다.
 * finance 권한의 사용자는 정산 상태가 아닐 때만 변경 권한이 있습니다.
 */
const checkIfHaveAuthorityToChangeTrelloDetail = (
  authority: ForwardingAdminUserAuthority | undefined,
  projectStatus: BidProjectStatus
) => {
  if (authority === "manager") {
    if (
      projectStatus === "inSettlement" ||
      projectStatus === "settlementConfirmed" ||
      projectStatus === "settlementSecondConfirmed" ||
      projectStatus === "settlementComplete"
    ) {
      return true;
    }
    return false;
  }
  if (authority === "finance") {
    if (
      projectStatus === "inSettlement" ||
      projectStatus === "settlementConfirmed" ||
      projectStatus === "settlementSecondConfirmed" ||
      projectStatus === "settlementComplete"
    ) {
      return false;
    }
    return true;
  }

  return false;
};

/**
 * 내륙 파트너가 등록되었는지 확인합니다.
 *
 * @param v - Trello 데이터 (TrelloBidList, ExportTrelloList, PartnerTrelloList 중 하나)
 * @returns 내륙 파트너가 등록되었는지 여부
 */
const checkIsInlandPartnerHasRegistered = (
  v: TrelloBidList | ExportTrelloList | PartnerTrelloList
) => {
  const inlandPartnerId = v.accountPayables.find((v) => {
    return v.domain === "inland" || v.domain === "parcel";
  });
  if (inlandPartnerId?.partnerCompanyId) return true;

  return false;
};

/**
 * 관세사 파트너가 등록되었는지 확인합니다.
 *
 * @param v - Trello 데이터 (TrelloBidList, PartnerTrelloList 중 하나)
 * @returns 관세사 파트너가 등록되었는지 여부
 */
const checkIsCustomsPartnerHasRegistered = (
  v: TrelloBidList | PartnerTrelloList
) => {
  const customsPartnerId = v.accountPayables.find((v) => {
    return v.domain === "customs";
  });
  if (customsPartnerId?.partnerCompanyId) return true;

  return false;
};

/**
 * BL 번호가 등록되었는지 확인합니다.
 *
 * @param v - Trello 데이터 (TrelloBidList, PartnerTrelloList 중 하나)
 * @returns BL 번호가 등록되었는지 여부
 */
const checkIsBlNumberHasRegistered = (
  v: TrelloBidList | PartnerTrelloList
): boolean | undefined => {
  if (v.management.BLType !== null) {
    return true;
  }
  return false;
};

/**
 * 관리자 이름에 따라 색상 타입을 반환합니다.
 *
 * @param name - 관리자 이름
 * @returns 색상 타입 (green, blue, purple, orange, mint, pink, sky, brown, general 중 하나)
 */
const getColorTypeByAdminUserName = (name: string) => {
  if (name === "황호진") {
    return "green";
  }
  if (name === "정정훈") {
    return "blue";
  }
  if (name === "이철행") {
    return "purple";
  }
  if (name === "손석균") {
    return "orange";
  }
  if (name === "유한나") {
    return "mint";
  }
  if (name === "오솔지") {
    return "pink";
  }
  if (name === "최용찬") {
    return "sky";
  }
  if (name === "이중호") {
    return "brown";
  } else {
    return "general";
  }
};

/**
 * TODO: attachmentFlag가 없음 확인 후 추가
 *
 * 관세 통관이 시작되었는지 확인합니다.
 *
 * @param trelloData - Trello 데이터 (TrelloBidList 또는 PartnerTrelloList 중 하나)
 * @returns 관세 통관이 시작되었는지 여부
 *
 * @remarks
 * attachmentFlag가 true이고, 관세사 파트너가 등록되어 있으며, 첫 번째 인보이스가 발송되지 않았고, accountPayables에 관세 통관 시작 플래그가 없는 경우에만 true를 반환합니다.
 */
const checkIfCustomsClearanceHasStarted = (
  trelloData: TrelloBidList | PartnerTrelloList
) => {
  if (
    trelloData.attachmentFlag &&
    checkIsCustomsPartnerHasRegistered(trelloData) &&
    !trelloData.invoices[0].isSended &&
    !checkCustomsClearanceStartFlag(trelloData.accountPayables)
  ) {
    return true;
  }
  return false;
};

/**
 * ETA 3일 전부터 체크, manager 가 스케줄 변동 모달에서 아니오를 선택한 경우 confirmFlag 가 true 가 되어 false return.
 *
 * @param v - Trello 데이터 (TrelloBidList, ExportTrelloList, PartnerTrelloList 중 하나)
 * @returns ETA가 3일 이내로 남았고 confirmFlag가 false인 경우 true, 그렇지 않으면 false
 */
const checkETAExpires = (
  v: TrelloBidList | ExportTrelloList | PartnerTrelloList
) => {
  const today = new Date();

  const formattedToday = toFormattedDate(today, "YYYY-MM-DD");

  const formattedETA = toFormattedDate(v.management.fullETA, "YYYY-MM-DD");

  const untilExpirationDays = getDifferentDate(
    formattedToday,
    formattedETA,
    "day"
  );

  if (untilExpirationDays <= 3 && v.management.confirmFlag === false)
    return true;

  return false;
};

/**
 * 스케줄링 중일 때 ETD가 만료되었는지 확인합니다.
 *
 * @param v - Trello 데이터 (TrelloBidList, ExportTrelloList, PartnerTrelloList 중 하나)
 * @returns 스케줄링 중이고 ETD가 만료되었으며 confirmFlag가 false인 경우 true, 그렇지 않으면 false
 */
const checkIfETDExpiresWhenScheduling = (
  v: TrelloBidList | ExportTrelloList | PartnerTrelloList
) => {
  const today = new Date();
  if (v.projectStatus === "scheduling") {
    if (
      getRemainedDays(v.management.fullETD, today) >= 0 &&
      v.management.confirmFlag === false
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

/**
 * 내륙 운임 결제 요청이 있는지 확인합니다.
 *
 * @param v - Trello 데이터 (TrelloBidList, ExportTrelloList, PartnerTrelloList 중 하나)
 * @returns 내륙 운임 결제 요청이 있는 경우 true, 그렇지 않으면 false
 */
const checkIsInlandFarePaymentRequest = (
  v: TrelloBidList | ExportTrelloList | PartnerTrelloList
) => {
  if (v.projectStatus === "completeCustoms") {
    if (v.endAddress) {
      if (
        v.containCustoms &&
        v.attachmentFlag &&
        v.management.confirmFlag &&
        checkIsInlandPartnerHasRegistered(v)
      ) {
        return true;
      } else if (
        !v.containCustoms &&
        v.management.confirmFlag &&
        checkIsInlandPartnerHasRegistered(v)
      ) {
        return true;
      } else {
        return false;
      }
    }

    if (!v.endAddress) {
      if (v.containCustoms && v.attachmentFlag && v.management.confirmFlag) {
        return true;
      } else if (!v.containCustoms && v.management.confirmFlag) {
        return true;
      } else {
        return false;
      }
    }
  } else {
    return false;
  }
};

/**
 * 운송 타입을 한글로 변환합니다.
 *
 * @param transportType - 운송 타입 (sea, air, inland 중 하나)
 * @returns 한글로 변환된 운송 타입
 */
const changeTransPortTypeToKr = (transportType: BidTransportType) => {
  switch (transportType) {
    case "sea":
      return "해상";
    case "air":
      return "항공";
    case "inland":
      return "내륙";
    default: {
      return "-";
    }
  }
};

/**
 * Trello 견적의 종료 항구 이름과 목적지를 반환합니다.
 *
 * @param trelloDetail - Trello 견적 상세 정보
 * @returns 종료 항구 이름과 목적지
 */
const getTrelloBidEndPortNameAndDestination = (
  trelloDetail: TrelloBidDetail
) => {
  const destination = getCountryCodeKR(trelloDetail.endCountry);
  let endPortName = "";
  if (trelloDetail.endPort) {
    endPortName = trelloDetail.endPort.name
      ? trelloDetail.endPort.name
      : trelloDetail.startPort.nameEN;
  } else if (trelloDetail.endViaPort) {
    endPortName = `${
      trelloDetail.endViaPort.name || trelloDetail.endViaPort.nameEN
    }`;
  }
  return `${destination ? destination : ""} ${endPortName}`;
};

/**
 * Trello 견적의 시작 항구 이름과 목적지를 반환합니다.
 *
 * @param trelloDetail - Trello 견적 상세 정보
 * @returns 시작 항구 이름과 목적지
 */
const getTrelloBidStartPortNameAndDestination = (
  trelloDetail: TrelloBidDetail
) => {
  const destination = getCountryCodeKR(trelloDetail.startCountry);
  let startName = "";
  if (trelloDetail.startPort) {
    startName = trelloDetail.startPort.name
      ? trelloDetail.startPort.name
      : trelloDetail.startPort.nameEN;
  } else if (trelloDetail.startAddress && trelloDetail.startViaPort) {
    startName = `${
      trelloDetail.startViaPort.name || trelloDetail.startViaPort.nameEN
    }`;
  }
  return `${destination ? destination : ""} ${startName}`;
};

/**
 * 내륙 운송 타입을 한글로 변환합니다.
 *
 * @param value - 내륙 운송 타입 (consol, sole, parcel 중 하나)
 * @returns 한글로 변환된 내륙 운송 타입
 */
const changeInlandTypeToNameKr = (
  value: InlnadTransportType | ExportationInlandTransportType | null
) => {
  switch (value) {
    case "consol":
      return "합차";
    case "sole":
      return "독차";
    case "parcel":
      return "택배";
    default:
      return "-";
  }
};

/**
 * 혼재 서비스의 종료 주소를 반환합니다.
 *
 * @param trelloDetail - Trello 견적 상세 정보
 * @returns 혼재 서비스의 종료 주소
 */
const getConsolidationEndAddress = (trelloDetail: TrelloBidDetail) => {
  if (trelloDetail.endAddress) {
    if (trelloDetail.endAddressDetail === null) {
      return `${trelloDetail.zone?.name || ""}/${trelloDetail.endAddress}`;
    }
    return `${trelloDetail.endAddress}/${trelloDetail.endAddressDetail}`;
  }
  return "-";
};

/**
 * 일반 서비스의 종료 주소를 반환합니다.
 *
 * @param trelloDetail - Trello 견적 상세 정보
 * @returns 일반 서비스의 종료 주소
 */
const getGeneralEndAddress = (trelloDetail: TrelloBidDetail) => {
  if (trelloDetail.endAddress) {
    return `${trelloDetail.endAddress}/${trelloDetail.endAddressDetail}`;
  }
  return "-";
};

/**
 * 혼재 서비스의 시작 주소를 반환합니다.
 *
 * @param trelloDetail - Trello 견적 상세 정보
 * @returns 혼재 서비스의 시작 주소
 */
const getConsolidationStartAddress = (trelloDetail: TrelloBidDetail) => {
  return trelloDetail.wareHouse.addressCN;
};

/**
 * 일반 서비스의 시작 주소를 반환합니다.
 *
 * @param trelloDetail - Trello 견적 상세 정보
 * @returns 일반 서비스의 시작 주소
 */
const getGeneralStartAddress = (trelloDetail: TrelloBidDetail) => {
  if (trelloDetail.startAddress) {
    if (trelloDetail.isImport) {
      return trelloDetail.startAddress;
    }
    return `${trelloDetail.startAddress}/${trelloDetail.startAddressDetail}`;
  }
  return "-";
};

/**
 * 서비스 타입에 따라 종료 주소를 반환합니다.
 *
 * @param trelloDetail - Trello 견적 상세 정보
 * @returns 서비스 타입에 따른 종료 주소
 */
const returnEndAddress = (trelloDetail: TrelloBidDetail) => {
  if (trelloDetail.serviceType === "consolidation") {
    return getConsolidationEndAddress(trelloDetail);
  }
  return getGeneralEndAddress(trelloDetail);
};

/**
 * 서비스 타입에 따라 시작 주소를 반환합니다.
 *
 * @param trelloDetail - Trello 견적 상세 정보
 * @returns 서비스 타입에 따른 시작 주소
 */
const returnStartAddress = (trelloDetail: TrelloBidDetail) => {
  if (trelloDetail.serviceType === "consolidation") {
    return getConsolidationStartAddress(trelloDetail);
  }
  return getGeneralStartAddress(trelloDetail);
};

/**
 * 프로젝트 상태가 정산 상태인지 확인합니다.
 *
 * @param projectStatus - 프로젝트 상태
 * @returns 정산 상태인 경우 true, 그렇지 않으면 false
 */
const checkIsSettlement = (projectStatus: BidProjectStatus | undefined) => {
  if (
    projectStatus === "inSettlement" ||
    projectStatus === "settlementRejected" ||
    projectStatus === "settlementConfirmed" ||
    projectStatus === "settlementSecondConfirmed" ||
    projectStatus === "settlementComplete"
  ) {
    return true;
  }
  return false;
};

/**
 * 부가가치세(VAT) 금액을 계산합니다.
 *
 * @param item - 출금 양식 상세 정보
 * @param totalPrice - 총 금액
 * @param companyType - 회사 타입
 * @returns 계산된 부가가치세 금액
 */
const calculateVATPrice = (
  item: WithdrawalFormDetail,
  totalPrice: number,
  companyType: PartnerBusinessArea
) => {
  if (!item.name) return 0;

  if (item.name.includes("VAT")) {
    return Math.floor(totalPrice / 10);
  }

  if (companyType === "foreign" || !item.isVAT) {
    return 0;
  }

  return Math.floor(totalPrice / 10);
};

/**
 * 출금 양식의 환율을 반환합니다.
 *
 * @param isNewCurrency - 새로운 통화인지 여부
 * @param inputCurrency - 입력된 통화 값
 * @param isInputCurrency - 입력된 통화 값인지 여부
 * @param saveExchangeRate - 저장된 환율 값
 * @param exchangeRate - 환율 값
 * @returns 환율 값
 */
const getWithdrawalExchangeRate = ({
  isNewCurrency,
  inputCurrency,
  isInputCurrency,
  saveExchangeRate,
  exchangeRate,
}: {
  isNewCurrency: boolean;
  inputCurrency: number;
  isInputCurrency: boolean;
  saveExchangeRate: number;
  exchangeRate: number;
}) => {
  if (isNewCurrency) {
    if (isInputCurrency) {
      return inputCurrency;
    }
    return saveExchangeRate;
  }

  if (isInputCurrency) {
    return inputCurrency;
  }
  return exchangeRate;
};

//TODO: 매출매입관리에서 보류 판단
// const getDepositCertificate = (
//   type: PartnerBusinessArea | undefined,
//   partnerManagement: TrelloBidDetailPartnersManagement
// ) => {
//   switch (type) {
//     case "wareHouse":
//       return partnerManagement.wareHouseDepositReceipt;
//     case "customs":
//       return partnerManagement.customsDepositReceipt;
//     case "shipping":
//       return partnerManagement.shippingDepositReceipt;
//     case "etc":
//       return partnerManagement.etcDepositReceipt;
//     case "etc2":
//       return partnerManagement.etc2DepositReceipt;
//     case "domestic":
//       return partnerManagement.domesticDepositReceipt;
//     case "foreign":
//       return partnerManagement.foreignDepositReceipt;
//     default:
//       return;
//   }
// };

/**
 * BL 타입에 따라 BL 번호가 등록되었는지 확인합니다.
 *
 * @param management - Trello 견적 관리 정보
 * @returns BL 번호가 등록된 경우 true, 그렇지 않으면 false
 */
const checkIsBlNumberByBLType = (management: TrelloBidManagement) => {
  if (management.BLType === "HBL") {
    if (management.hBL) {
      return true;
    } else {
      return false;
    }
  }
  if (management.BLType === "MBL" || management.BLType === "DirectMBL") {
    if (management.mBL) {
      return true;
    } else {
      return false;
    }
  }
  if (management.BLType === "ALL") {
    if (management.mBL && management.hBL) {
      return true;
    } else {
      return false;
    }
  }

  return false;
};

/**
 * Trello 파트너 자동 완성 값 반환합니다.
 *
 * @param trelloDetail - Trello 견적 상세 정보
 * @param partnerId - 파트너 ID
 * @param domain - 파트너 비즈니스 영역
 * @returns 자동 완성 값
 */
const getTrelloPartnerAutoCompleteValue = (
  trelloDetail: TrelloBidDetail,
  partnerId: number | null,
  domain: PartnerBusinessArea
) => {
  const { management } = trelloDetail;
  if (domain === "customs" || domain === "shipDaCustoms") {
    //초기값과 구별하는 화주발 송 전용 value 추가
    return !management.documentMailingFlag && !trelloDetail.containCustoms
      ? "shipper"
      : partnerId || null;
  }
  return partnerId || null;
};

/**
 * Trello 파트너 자동 완성 라벨 반환합니다.
 *
 * @param trelloDetail - Trello 견적 상세 정보
 * @param partnerName - 파트너 이름
 * @param domain - 파트너 비즈니스 영역
 * @returns 자동 완성 라벨
 */
const getTrelloPartnerAutoCompleteLabel = (
  trelloDetail: TrelloBidDetail,
  partnerName: string | null,
  domain: PartnerBusinessArea
) => {
  const { management } = trelloDetail;
  if (domain === "customs" || domain === "shipDaCustoms") {
    return !management.documentMailingFlag && !trelloDetail.containCustoms
      ? "화주 발송"
      : partnerName || "-";
  }
  return partnerName || "-";
};

/**
 * 문서 데이터에서 CBM 값을 추출합니다.
 *
 * @param data - 문서 데이터 문자열
 * @returns 추출된 CBM 값
 */
const getDocumentDataCbm = (data: string) => {
  const cbmIndex = data.indexOf("/");
  return data.substring(0, cbmIndex - 1);
};

/**
 * 문서 데이터에서 무게 값을 추출합니다.
 *
 * @param data - 문서 데이터 문자열
 * @returns 추출된 무게 값
 */
const getDocumentDataWeight = (data: string) => {
  const slashIndex = data.indexOf("/");
  return data.substring(slashIndex + 2, data.length).replace(",", "");
};

/**
 * 택배 요청 문서의 텍스트 필드 라벨을 반환합니다.
 *
 * @param textFieldType - 텍스트 필드 타입
 * @returns 텍스트 필드 라벨
 */
const getParcelRequestDocumentTextFieldLabel = (
  textFieldType: ParcelRequestDocumentFormType
) => {
  switch (textFieldType) {
    case "blNumber":
      return "BL#";
    case "consignee":
      return "화주";
    case "packageInfo":
      return "package";
    case "weight":
      return "weight";
    case "cbm":
      return "cbm";
    case "warehouseName":
      return "픽업창고";
    case "warehouseAddress":
      return "창고주소";
    case "warehouseContact":
      return "창고 전화번호";
    case "arrivalCompany":
      return "받는 곳";
    case "destinationAddress":
      return "받는 곳 주소";
    case "recipient":
      return "받는 이";
    case "phone":
      return "받는 이 번호";
    case "faxNumber":
      return "Fax";
    case "adminEmail":
      return "E-mail";
    case "adminPhone":
      return "Tel";
    case "adminName":
      return "운영 담당자";
    default:
      return false;
  }
};

/**
 * 출고 지시서 문서의 텍스트 필드 라벨을 반환합니다.
 *
 * @param textFieldType - 텍스트 필드 타입
 * @returns 텍스트 필드 라벨
 */
const getHouseDeliveryOrderDocumentTextFieldLabel = (
  textFieldType: HouseDeliveryOrderDocumentFormType
) => {
  switch (textFieldType) {
    case "blNumber":
      return "B/L NO.";
    case "shipper":
      return "SHIPPER";
    case "consignee":
      return "CONSIGNEE";
    case "notiInfo":
      return "Notify Party";
    case "departureInfo":
      return "Port of Loading";
    case "arrivalInfo":
      return "Final Destination";
    case "arrivalDate":
      return "Arrival Date";
    case "voyageNumber":
      return "VSL/VYG";
    case "prnm":
      return "Description";
    case "packageInfo":
      return "package";
    case "weight":
      return "weight";
    case "cbm":
      return "cbm";
    default:
      return false;
  }
};

/**
 * 창고 문서의 텍스트 필드 라벨을 반환합니다.
 *
 * @param textFieldType - 텍스트 필드 타입
 * @returns 텍스트 필드 라벨
 */
const getWarehouseDocumentTextFieldLabel = (
  textFieldType: WarehouseDocumentFormType
) => {
  switch (textFieldType) {
    case "warehouseName":
      return "창고";
    case "company":
      return "화주명";
    case "blNumber":
      return "HBL#";
    case "packageInfo":
      return "PKG";
    case "weight":
      return "WEIGHT";
    case "cbm":
      return "RTON";
    case "comment":
      return "comment";
    case "adminPhone":
      return "Tel.";
    case "faxNumber":
      return "Fax";
    case "adminEmail":
      return "E-mail";
    case "adminName":
      return "운영 담당자";
    default:
      return false;
  }
};

/**
 * 파일 도메인을 한글로 변환합니다.
 *
 * @param fileDomain - 파일 도메인
 * @returns 한글로 변환된 파일 도메인
 */
const changeFileDocumentDomainToKr = (
  fileDomain: ForwardingAdminFileDomain | undefined,
  isImport: boolean
) => {
  switch (fileDomain) {
    // HBL은 실제로 존재하는 파일 도메인이 아니지만 수출에서 MBL과 구분하기 위해 추가
    case "HBL":
      return "HBL";
    case "BL":
      // 수출에서는 MBL로 표시
      return isImport ? "B/L" : "MBL";
    case "CI_PL":
      return "C/I&P/L";
    case "CO":
      return "C/O";
    case "brn":
      return "사업자등록증";
    case "certificate":
      return "인증서류";
    case "etc":
      return "기타서류";
    case "importTax":
      return "수입세금계산서";
    case "importDeclaration":
      return "수입신고필증";
    case "houseDo":
      return "HDO";
    case "releaseRequest":
      return "출고요청서";
    case "parcelRequest":
      return "택배요청서";
    case "adminTemp":
      return "기타보관용";
    case "exportDeclaration":
      return "수출신고필증";
    case "arrivalNotice":
      return "AN";
    default:
      return;
  }
};

/**
 * 파일 삭제 권한이 있는지 확인합니다.
 *
 * @param fileAuthority - 파일 권한
 * @param isAdmin - 관리자 여부
 * @returns 파일 삭제 권한이 있는지 여부
 *
 * @remarks
 * 매출&매입 API 개발 완료 후 isAdmin 조건 삭제 필요
 */
const checkIfHaveAuthorityToDelete = (
  fileAuthority: ForwardingAdminFileAuthority | undefined,
  isAdmin: boolean | undefined
) => {
  if (APP_NAME === "partner-admin") {
    //TODO:  매출&매입 API 개발 완료후 isAdmin 조건 삭제
    if (fileAuthority === "CustomsAdmin" || isAdmin === false) {
      return false;
    }
    return true;
  }
  return false;
};

/**
 * 파일 권한을 한글로 변환합니다.
 *
 * @param fileAuthority - 파일 권한
 * @returns 한글로 변환된 파일 권한
 */
const changeFileAuthorityToKr = (
  fileAuthority: ForwardingAdminFileAuthority
) => {
  switch (fileAuthority) {
    case "CustomsAdmin":
      return "(파트너)";
    case "User":
      return "(고객)";
    default:
      return "";
  }
};

/**
 * 관세 발송 여부를 확인합니다.
 *
 * @param isCustomSended - 관세 발송 여부
 * @param isSended - 발송 여부
 * @param invoiceType - 인보이스 타입
 * @returns 관세 발송 여부에 따른 메시지
 */
const checkIsCustomSended = ({
  isCustomSended,
  isSended,
  invoiceType,
}: {
  isCustomSended: boolean | undefined;
  isSended: boolean | undefined;
  invoiceType: InvoiceType;
}) => {
  if (isSended) {
    if (invoiceType === "refund") {
      return "부킹 커미션 발송";
    }
    return "고객사 발송";
  }

  if (isCustomSended) {
    return "관세사 발송";
  }
  return "-";
};

/**
 * 구매 도메인을 한글로 변환합니다.
 *
 * @param domain - 파트너 비즈니스 영역
 * @returns 한글로 변환된 구매 도메인
 */
const changePurchaseDomainToKr = (domain: PartnerBusinessArea) => {
  switch (domain) {
    case "foreign":
      return "해외파트너";
    case "domestic":
      return "국내파트너";
    case "customs":
      return "관세사";
    case "shipDaCustoms":
      return "관세사";
    case "customsDuty":
      return "세관";
    case "inland":
      return "내륙운송사";
    case "parcel":
      return "화물택배사";
    case "wareHouse":
      return "창고";
    case "shipping":
      return "선사";
    default:
      return "기타";
  }
};

/**
 * 파트너 비즈니스 영역을 업로드 대상 키로 변환합니다.
 *
 * @param partnerBusinessArea - 파트너 비즈니스 영역
 * @returns 업로드 대상 키
 */
const changePartnerBusinessAreaToUploadTarget = (
  partnerBusinessArea: PartnerBusinessArea
) => {
  switch (partnerBusinessArea) {
    case "foreign":
      return "foreignPartnerInvoice";
    case "domestic":
      return "domesticPartnerInvoice";
    case "customs":
      return "customsPartnerInvoice";
    case "shipDaCustoms":
      return "customsPartnerInvoice";
    case "customsDuty":
      return "customsInvoice";
    case "inland":
      return "inlandPartnerInvoice";
    case "wareHouse":
      return "warehousePartnerInvoice";
    case "shipping":
      return "shippingPartnerInvoice";
    case "parcel":
      return "parcelPartnerInvoice";
    default:
      return "etcPartnerInvoice";
  }
};

/**
 * 파트너 비즈니스 영역을 데이터 키로 변환합니다.
 *
 * @param partnerBusinessArea - 파트너 비즈니스 영역
 * @returns 데이터 키
 */
const changePartnerBusinessAreaToDataKey = (
  partnerBusinessArea: PartnerBusinessArea
) => {
  switch (partnerBusinessArea) {
    case "foreign":
      return "foreignPartnerInvoices";
    case "domestic":
      return "domesticPartnerInvoices";
    case "customs":
      return "customsPartnerInvoices";
    case "shipDaCustoms":
      return "customsPartnerInvoices";
    case "customsDuty":
      return "customsInvoices";
    case "inland":
      return "inlandPartnerInvoices";
    case "wareHouse":
      return "warehousePartnerInvoices";
    case "shipping":
      return "shippingPartnerInvoices";
    default:
      return "etcPartnerInvoices";
  }
};

/**
 * 인보이스 타입을 한글로 변환합니다.
 *
 * @param invoiceType - 인보이스 타입
 * @returns 한글로 변환된 인보이스 타입
 */
const changeInvoiceTypeToKr = (invoiceType: InvoiceType) => {
  switch (invoiceType) {
    case "invoice":
      return "거래명세서";
    case "warehouseReceipt":
      return "창고료";
    case "etcDeposit":
      return "기타입금";
    case "refund":
      return "부킹 커미션";
  }
};

/**
 * 인보이스 상태를 확인합니다.
 *
 * @param isTemporary - 임시 저장 여부
 * @returns 인보이스 상태
 */
const checkInvoiceStatus = (isTemporary: boolean | undefined) => {
  if (isTemporary === false) {
    return "저장";
  }

  if (isTemporary) {
    return "임시저장";
  }

  return "-";
};

/**
 * 관세 통관 시작 플래그를 확인합니다.
 *
 * @param accountPayable - 결제 정보 배열
 * @returns 관세 통관 시작 플래그 여부
 */
const checkCustomsClearanceStartFlag = (accountPayable: AccountPayable[]) => {
  const customsAccountPayable = accountPayable.find((v) => {
    return v.domain === "customs";
  });

  if (customsAccountPayable) {
    return customsAccountPayable.customsClearanceStartFlag;
  }
  return false;
};

/**
 * 내륙 파트너 이름을 반환합니다.
 *
 * @param accountPayables - 결제 정보 배열
 * @returns 내륙 파트너 이름
 */
const getInlandPartnerName = (accountPayables: AccountPayable[]) => {
  const inlandAccountPayable = accountPayables.find((v) => {
    return v.domain === "inland" || v.domain === "parcel";
  });

  if (inlandAccountPayable?.partner) {
    return inlandAccountPayable.partner.name;
  }
  return;
};

/**
 * 결제 파트너 도메인이 관세사인지 확인합니다.
 *
 * @param accountPayable - 결제 정보 배열
 * @returns 관세사 도메인 여부
 */
const checkPurchasePartnerDomainIsCustoms = (
  accountPayable: AccountPayable[]
) => {
  const customsPartner = accountPayable.find((v) => {
    return v.domain === "customs";
  });

  if (customsPartner?.partner) {
    if (customsPartner?.partner.businessArea === "customs") {
      return true;
    }
    return false;
  }
  return false;
};

/**
 * 결제 파트너가 존재하는지 확인합니다.
 *
 * @param accountPayable - 결제 정보 배열
 * @param domain - 파트너 비즈니스 영역
 * @returns 결제 파트너 존재 여부
 */
const checkHasPurchasePartner = (
  accountPayable: AccountPayable[],
  domain: PartnerBusinessArea
) => {
  const purchasePartner = accountPayable.find((v) => {
    return v.domain === domain;
  });

  if (purchasePartner?.partner) {
    return true;
  }
  return false;
};

/**
 * 파트너 회사 타입을 반환합니다.
 *
 * @param partnerList - 파트너 목록
 * @param partnerId - 파트너 ID
 * @returns 파트너 회사 타입
 */
const getPartnerCompanyType = (
  partnerList: PartnerListItem[],
  partnerId: number
) => {
  return partnerList.find((v) => {
    return v.id === partnerId;
  })?.businessArea;
};

/**
 * 파트너 회사 이름을 반환합니다.
 *
 * @param partnerList - 파트너 목록
 * @param partnerId - 파트너 ID
 * @returns 파트너 회사 이름
 */
const getPartnerCompanyName = (
  partnerList: PartnerListItem[],
  partnerId: number
) => {
  return partnerList.find((v) => {
    return v.id === partnerId;
  })?.name;
};

/**
 * 구매 도메인을 템플릿 카테고리로 변환합니다.
 *
 * @param domain - 파트너 비즈니스 영역
 * @returns 템플릿 카테고리
 */
const changePurchaseDomainToTemplateCategory = (
  domain: PartnerBusinessArea
) => {
  switch (domain) {
    case "foreign":
      return "foreign";
    case "domestic":
      return "domestic";
    case "customs":
      return "customs";
    case "shipDaCustoms":
      return "customs";
    case "customsDuty":
      return "customsDuty";
    case "inland":
      return "inland";
    case "parcel":
      return "inland";
    case "wareHouse":
      return "warehouse";
    case "shipping":
      return "shipping";
    default:
      return "apEtc";
  }
};

export {
  checkIfHaveAuthorityToChangeTrelloDetail,
  checkIsInlandPartnerHasRegistered,
  checkIsCustomsPartnerHasRegistered,
  checkIsBlNumberHasRegistered,
  getColorTypeByAdminUserName,
  checkIfCustomsClearanceHasStarted,
  checkETAExpires,
  checkIfETDExpiresWhenScheduling,
  checkIsInlandFarePaymentRequest,
  changeTransPortTypeToKr,
  getTrelloBidEndPortNameAndDestination,
  getTrelloBidStartPortNameAndDestination,
  changeInlandTypeToNameKr,
  returnEndAddress,
  returnStartAddress,
  checkIsSettlement,
  calculateVATPrice,
  getWithdrawalExchangeRate,
  checkIsBlNumberByBLType,
  getTrelloPartnerAutoCompleteValue,
  getDocumentDataCbm,
  getDocumentDataWeight,
  getParcelRequestDocumentTextFieldLabel,
  getHouseDeliveryOrderDocumentTextFieldLabel,
  getWarehouseDocumentTextFieldLabel,
  changeFileDocumentDomainToKr,
  checkIfHaveAuthorityToDelete,
  changeFileAuthorityToKr,
  checkIsCustomSended,
  changePurchaseDomainToKr,
  changePartnerBusinessAreaToUploadTarget,
  changePartnerBusinessAreaToDataKey,
  changeInvoiceTypeToKr,
  getTrelloPartnerAutoCompleteLabel,
  checkInvoiceStatus,
  checkHasPurchasePartner,
  checkCustomsClearanceStartFlag,
  getInlandPartnerName,
  checkPurchasePartnerDomainIsCustoms,
  getPartnerCompanyType,
  getPartnerCompanyName,
  changePurchaseDomainToTemplateCategory,
};
